// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-links-index-js": () => import("./../../../src/pages/links/index.js" /* webpackChunkName: "component---src-pages-links-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-class-groups-page-js": () => import("./../../../src/templates/class-groups-page.js" /* webpackChunkName: "component---src-templates-class-groups-page-js" */),
  "component---src-templates-clubs-page-js": () => import("./../../../src/templates/clubs-page.js" /* webpackChunkName: "component---src-templates-clubs-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-junior-page-js": () => import("./../../../src/templates/junior-page.js" /* webpackChunkName: "component---src-templates-junior-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-timetable-calendar-page-js": () => import("./../../../src/templates/timetable-calendar-page.js" /* webpackChunkName: "component---src-templates-timetable-calendar-page-js" */)
}

